
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    fullImg: Boolean,
    items: Array,
    styleType: Number,
  },
  data() {
    return {
      selectedIndex: 0,
      defaultColor: "#00000080",
      selectedColor: "#f1c889",
    };
  },
  methods: {
    onClick(index: number) {
      this.selectedIndex = index;
      this.selectedColor = "#f1c889";

      window.document.title = this.$props.items[index].name;
      this.$emit("switchTab", index);
    },
  },
})
export default class CustomSlider extends Vue {}
