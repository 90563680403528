
export default {
  name: "Footer",
  data() {
    return {
      copyright: "Copyright © 2020-2021 蕙芷农业 版权所有",
      address: "沪ICP备2021006767号",
    };
  },
  created() {
    // 创建时
    const hostName = location.hostname;
    console.log(hostName);
    const hostArr = ['gnfo.net', 'gnso.cn', 'gnqq.net', 'gnwe.net', 'gnmi.net'];
    let hostIndex = -1;
    hostArr.forEach((item, index) => {
      if (hostName.indexOf(item) > -1) {
        hostIndex = index;
      }
    });
    switch (hostIndex) {
      case 0: { (this as any).hostText(hostIndex); break; }
      case 1: { (this as any).hostText(hostIndex); break; }
      case 2: { (this as any).hostText(hostIndex); break; }
      case 3: { (this as any).hostText(hostIndex); break; }
      case 4: { (this as any).hostText(hostIndex); break; }
      // default: console.log((this as any).hostText(hostIndex));
    }
    console.log(hostIndex);
    // if (hostName.indexOf('gnfo.net') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-1";
    // } else if (hostName.indexOf('gnso.cn') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-2";
    // } else if (hostName.indexOf('gnqq.net') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-3";
    // } else if (hostName.indexOf('gnwe.net') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-4";
    // } else if (hostName.indexOf('gnmi.net') > -1) {
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   (this as any).address = "沪ICP备2020038115号-5";
    // }
  },
  methods: {
    hostText(index:any) {
      (this as any).copyright = "Copyright © 2020-2021 归农信息 版权所有";
      (this as any).address = "沪ICP备2020038115号-" + (index + 1);
    },
  },
};
